
import Component from 'vue-class-component'
import { Mixins } from 'vue-property-decorator'
import TableMixin from '@/mixins/TableMixin.vue'
// import moment from 'moment'
import AdminModelForm from '@/views/Admin/Brands/components/AdminModelForm.vue'
import Brands from '@/apis/Brands'
// import _ from 'lodash'
// import { UserStatusId } from '@/typings/AdminTyping'

@Component({
  name: 'editModel',
  components: {
    AdminModelForm
  }
}
)

export default class EditModel extends Mixins(TableMixin) {
  simpleTable = true;
  brandId: any = null;
  modelId: any = null;
  // ModelDetails = {};
  BrandDetails = {};

  ModelDetails = {
    car_brand_id: null,
    is_car: null,
    is_truck: null,
    new_mileage: null,
    with_mileage: null
  }

  created() {
    this.brandId = this.$router.currentRoute.params.id
    this.modelId = this.$router.currentRoute.params.modelId

    this.fetchDetailsForUpdateCarBrand(this.brandId)
    this.ModelDetails.car_brand_id = this.brandId
    this.fetchModelDetails(this.modelId)
  }

  fetchDetailsForUpdateCarBrand(brandId: number) {
    Brands.fetchDetailsForUpdateCarBrand(brandId)
      .then(({ data }) => {
        this.BrandDetails = data.brandDetails
      })
      .catch((errors) => {
        console.log(errors)
      })
  }

  fetchModelDetails(modelId: number) {
    Brands.fetchModelDetails(modelId)
      .then(({ data }) => {
        this.ModelDetails = data
        console.log(this.ModelDetails)
      })
      .catch((errors) => {
        console.log(errors)
      })
  }

  updateModel() {
    console.log(this.ModelDetails)
    if (this.ModelDetails.is_car === 0 && this.ModelDetails.is_truck === 0) {
      // @ts-ignore
      this.showErrorSystemNotification('Не выставлен тип модели')
      return
    }

    if (this.ModelDetails.new_mileage === 0 && this.ModelDetails.with_mileage === 0) {
      // @ts-ignore
      this.showErrorSystemNotification('Не выставлено состояние модели')
      return
    }

    Brands.updateModel(this.brandId, this.modelId, this.ModelDetails)
      .then(({ data }) => {
        if (data.success === true) {
          // @ts-ignore
          this.showSuccessSystemNotification(data.msg)
          this.$router.push({ name: 'Brands' })
        } else {
          // @ts-ignore
          this.showErrorSystemNotification(data.msg)
        }
      })
      .catch(() => {
        const msg = 'Что-то пошло не так'
        // @ts-ignore
        this.showErrorSystemNotification(msg)
      })
  }
}
